:root {
  --text-color: #fff;
  --dark-blue: #110035;
}

@keyframes fade-in-out {
  0%, 100% {
    background-image: url("/img/kbely_1.webp");
  }
  50% {
    background-image: url("/img/kbely_2.webp");
  }
}
body {
  background-color: var(--dark-blue);
  background-image: url("/img/background.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: var(--text-color);
  margin: 0 auto;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 70px 25px 0 25px;
}
@media (max-width: 768px) {
  .header {
    margin: 15px 15px 0 15px;
  }
}
.header__title {
  font-size: 49px;
  font-weight: bold;
  line-height: 125%;
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
}
.header__title span {
  display: block;
}
@media (max-width: 768px) {
  .header__title {
    display: none;
  }
}
.header__logo {
  display: inline-block;
  outline: none;
}
@media (max-width: 768px) {
  .header__logo {
    width: 190px;
    height: 90px;
  }
}

.services {
  margin: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .services {
    margin: 15px;
  }
}
.services__wrapper {
  width: calc(50% - 15px);
  position: relative;
}
@media (max-width: 768px) {
  .services__wrapper {
    width: 100%;
  }
}

.service {
  width: 100%;
  padding-bottom: 85%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 70px;
  display: flex;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}
.service--step {
  background-image: url("/img/step.webp");
}
.service--kbely {
  animation: fade-in-out 5s infinite;
}
.service__title {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: block;
  margin-top: auto;
  height: fit-content;
  padding: 30px 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(12px);
  color: var(--text-color);
  font-size: 54px;
  line-height: 54px;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 768px) {
  .service__title {
    font-size: 34px;
    padding: 0;
  }
}
@media (max-width: 768px) {
  .service {
    padding-bottom: 65%;
    border-radius: 30px;
  }
}
@media (max-width: 768px) {
  .service:first-of-type {
    margin-bottom: 15px;
  }
}

.footer {
  min-height: 50px;
  background: rgba(0, 0, 0, 0.55);
}
.footer__container {
  text-align: right;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  padding-top: 15px;
}
.footer__container a {
  text-decoration: none;
  border: none;
  color: var(--text-color);
  font-weight: bold;
}
.footer__container a:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .footer__container {
    margin: 15px;
    padding-top: 0;
    text-align: left;
  }
}

.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: -6px;
  margin-left: 6px;
  margin-right: 3px;
}
.icon--envelope {
  width: 25px;
  height: 25px;
  background-image: url("/img/envelope.svg");
}
.icon--phone {
  width: 22px;
  height: 24px;
  background-image: url("/img/phone.svg");
}

.contacts {
  display: inline-block;
  margin: 0 25px 0 0;
  padding: 0;
}
@media (max-width: 768px) {
  .contacts {
    margin: 0;
  }
}
.contacts__item {
  display: inline;
}
@media (max-width: 768px) {
  .contacts__item {
    display: block;
    padding-bottom: 5px;
  }
}

.show-md-down {
  display: none;
}
@media (max-width: 768px) {
  .show-md-down {
    display: inline-block;
  }
}

.sticker {
  position: absolute;
  background-repeat: no-repeat;
  width: 100%;
  height: 116px;
  display: block;
  background-size: contain;
  pointer-events: none;
  z-index: 1;
  top: 0;
}
.sticker img {
  max-width: 100%;
  width: 100%;
}
.navbar {
  background-color: white;
  padding: 15px 18px;
  z-index: 9999;
  position: fixed;
  top: 0;
  width: calc(100% - 36px);
  max-width: 1884px;
}
.navbar + * {
  padding-top: 72px;
}
@media (max-width: 992px) {
  .navbar + * {
    padding-top: 54px;
  }
}
@media (max-width: 992px) {
  .navbar {
    padding: 6px 18px 6px;
  }
}
.navbar__wrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 992px) {
  .navbar__wrapper {
    height: 42px;
  }
}
.navbar__wrapper--login {
  max-width: 1110px;
}
.navbar__info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.navbar__info .dropdown, .navbar__info > i, .navbar__info .navbar__menu__item, .navbar__info .navbar__profile {
  margin-left: 20px;
}
@media (max-width: 992px) {
  .navbar__info > .navbar__menu__item {
    display: none;
  }
}
.navbar__profile {
  font-size: 14px;
  color: var(--dark-blue);
  font-weight: 700;
  display: flex;
  align-items: center;
}
.navbar__profile > span {
  cursor: pointer;
}
@media (max-width: 992px) {
  .navbar__profile > span {
    display: none;
  }
}
.navbar__profile img {
  cursor: pointer;
  border: 1px solid var(--green);
  border-radius: 100%;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin-left: 5px;
}
.navbar__button {
  width: 30px;
  height: 26px;
  position: relative;
  cursor: pointer;
  margin-left: 20px;
}
@media (min-width: 992px) {
  .navbar__button {
    display: none;
  }
}
.navbar__button__bar {
  background-color: var(--dark-blue);
  display: block;
  height: 2px;
  margin-bottom: 8px;
  position: relative;
  transition: 0.3s;
  width: 100%;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.navbar__button__bar:first-child {
  margin-top: 2px;
}
.navbar__button--open .navbar__button__bar:nth-child(2) {
  background-color: transparent;
}
.navbar__button--open .navbar__button__bar:first-child {
  animation-name: cross_top;
}
.navbar__button--open .navbar__button__bar:last-child {
  animation-name: cross_bottom;
}
@keyframes cross_top {
  60% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(10px) rotate(45deg);
  }
}
@keyframes cross_bottom {
  60% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(-10px) rotate(-45deg);
  }
}
.navbar__button--closed .navbar__button__bar:first-child {
  animation-name: cross_top_close;
}
.navbar__button--closed .navbar__button__bar:last-child {
  animation-name: cross_bottom_close;
}
@keyframes cross_top_close {
  0% {
    transform: translateY(10px) rotate(45deg);
  }
  40% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes cross_bottom_close {
  0% {
    transform: translateY(-10px) rotate(-45deg);
  }
  40% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.navbar__home {
  width: 133px;
  height: 40px;
  display: flex;
  align-items: flex-end;
}
@media (max-width: 992px) {
  .navbar__home {
    width: 88px;
    height: 26px;
  }
}
@media (max-width: 992px) {
  .navbar__wrapper:not(.navbar__wrapper--login) .navbar__home {
    width: 27px;
  }
}
.navbar__home img {
  width: 92px;
}
@media (max-width: 992px) {
  .navbar__home img {
    width: 61px;
  }
}
.navbar__home img:first-child {
  height: 100%;
  object-fit: contain;
}
@media (max-width: 992px) {
  .navbar__wrapper:not(.navbar__wrapper--login) .navbar__home img:last-child {
    display: none;
  }
}
.navbar__menu {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
@media (min-width: 992px) {
  .navbar__wrapper:not(.navbar__wrapper--login) .navbar__menu {
    display: none;
  }
}
@media (max-width: 992px) {
  .navbar__wrapper:not(.navbar__wrapper--login) .navbar__menu--open {
    display: flex;
  }
}
@media (max-width: 992px) {
  .navbar__menu {
    height: 0;
    min-height: 0;
    width: 100%;
    left: 0;
    z-index: 100;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(8px);
    top: 54px;
    background-color: rgba(255, 255, 255, 0.95);
    position: absolute;
  }
}
.navbar__menu__item {
  color: var(--dark-blue);
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-left: 57px;
}
@media (max-width: 992px) {
  .navbar__menu__item {
    padding: 10px 0;
    margin-left: 20px;
  }
}
.navbar__menu__item--dot {
  position: relative;
  padding-left: 32px;
}
@media (max-width: 992px) {
  .navbar__menu__item--dot {
    padding-left: 0;
  }
}
.navbar__menu__item--dot:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: var(--green);
  position: absolute;
  top: 50%;
  left: 23px;
  transform: translateY(-50%);
}
@media (max-width: 992px) {
  .navbar__menu__item--dot:before {
    content: none;
  }
}
@media (max-width: 992px) {
  .navbar__menu__item--bar {
    margin-left: 0 !important;
    padding-left: 20px;
    border-top: 1px solid var(--dark-blue);
  }
}
.navbar__menu__item strong {
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .navbar__menu--open {
    padding-top: 10px;
    min-height: calc(100vh - 54px) !important;
    overflow: scroll;
  }
}
@media (max-width: 992px) {
  .navbar--open {
    position: fixed;
    width: calc(100vw - 36px);
    top: 0;
    left: 0;
  }
}
@media (max-width: 992px) {
  .navbar--open + * {
    max-height: calc(100vh - 54px);
    min-height: calc(100vh - 54px);
    overflow: hidden;
  }
}

.side-nav {
  background-color: var(--dark-blue);
  position: fixed;
  left: 0;
  top: 121px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 217px);
  padding: 25px 12px 35px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 100;
}
@media (max-width: 768px) {
  .side-nav {
    bottom: 0;
    flex-direction: row;
    top: auto;
    width: calc(100% - 100px);
    height: auto;
    justify-content: space-between;
    padding: 6px 50px 14px;
    position: fixed;
  }
}
.side-nav:hover .side-nav__texts {
  width: 201px;
  padding-left: 8px;
  padding-right: 15px;
}
@media (max-width: 768px) {
  .side-nav:hover .side-nav__texts {
    display: none;
  }
}
.side-nav__texts {
  position: absolute;
  height: calc(100% - 60px);
  background-color: rgba(67, 70, 176, 0.8980392157);
  top: 0;
  left: 100%;
  padding: 25px 15px 35px 8px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  padding-left: 0;
  padding-right: 0;
  width: 0;
}
@media (max-width: 768px) {
  .side-nav__texts {
    display: none;
  }
}
.side-nav__texts .side-nav__item {
  line-height: 35px;
}
.side-nav__item {
  text-decoration: none;
  margin-bottom: 30px;
  color: white;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .side-nav__item {
    margin-bottom: 0;
  }
}
.side-nav__item--end {
  margin-top: auto;
  margin-bottom: 0;
}