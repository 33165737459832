
:root {
  --text-color: #fff;
  --dark-blue: #110035;
}

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
