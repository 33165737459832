@keyframes fade-in-out {

  0%,
  100% {
    background-image: url('/img/kbely_1.webp');
  }

  50% {
    background-image: url('/img/kbely_2.webp');
  }
}

body {
  background-color: var(--dark-blue);
  background-image: url('/img/background.webp');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: var(--text-color);
  margin: 0 auto;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 70px 25px 0 25px;

  @include media-breakpoint-down(md) {
    margin: 15px 15px 0 15px;
  }

  &__title {
    font-size: 49px;
    font-weight: bold;
    line-height: 125%;
    margin: 0;
    padding: 0;
    letter-spacing: 2px;

    span {
      display: block;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__logo {
    display: inline-block;
    outline: none;

    @include media-breakpoint-down(md) {
      width: 190px;
      height: 90px;
    }
  }
}

.services {
  margin: 25px;

  @include media-breakpoint-down(md) {
    margin: 15px;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__wrapper {
    width: calc(50% - 15px);
    position: relative;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

.service {
  width: 100%;
  padding-bottom: 85%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 70px;
  display: flex;
  text-decoration: none;
  position: relative;
  overflow: hidden;

  &--step {
    background-image: url('/img/step.webp');
  }

  &--kbely {
    animation: fade-in-out 5s infinite;
  }

  &__title {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: block;
    margin-top: auto;
    height: fit-content;
    padding: 30px 0;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(12px);
    color: var(--text-color);
    font-size: 54px;
    line-height: 54px;
    font-weight: bold;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 34px;
      padding: 0;
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 65%;
    border-radius: 30px;
  }

  &:first-of-type {
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }
  }
}

.footer {
  min-height: 50px;
  background: rgba(0, 0, 0, 0.55);

  &__container {
    text-align: right;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;
    padding-top: 15px;

    a {
      text-decoration: none;
      border: none;
      color: var(--text-color);
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-down(md) {
      margin: 15px;
      padding-top: 0;
      text-align: left;
    }
  }
}

.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: -6px;
  margin-left: 6px;
  margin-right: 3px;

  &--envelope {
    width: 25px;
    height: 25px;
    background-image: url('/img/envelope.svg');
  }

  &--phone {
    width: 22px;
    height: 24px;
    background-image: url('/img/phone.svg');
  }
}

.contacts {
  display: inline-block;
  margin: 0 25px 0 0;
  padding: 0;

  @include media-breakpoint-down(md) {
    margin: 0;
  }

  &__item {
    display: inline;

    @include media-breakpoint-down(md) {
      display: block;
      padding-bottom: 5px;
    }
  }
}

.show-md-down {
  display: none;

  @include media-breakpoint-down(md) {
    display: inline-block;
  }
}

.sticker {
  position: absolute;
  background-repeat: no-repeat;
  width: 100%;
  height: 116px;
  display: block;
  background-size: contain;
  pointer-events: none;
  z-index: 1;
  top: 0;

  img {
    max-width: 100%;
    width: 100%;
  }
  @include media-breakpoint-down(md) {
  }
}

